import axios, { AxiosInstance } from 'axios';
import config from '../config';

const API = (): AxiosInstance => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  
  const headers = user ? { Authorization: `bearer ${user.token}`, "company-id": user.role === "user" ? user.companies[0].id : -1} : undefined

  const instance = axios.create({
    headers,
    baseURL: `${config.apiUrl}/api`,
    timeout: 30000,
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('user');5
      } else {
        throw error;
      }
    },
  );

  return instance;
};

export default API;
